import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, SelectField, TextField, DateField, SearchInput, SelectInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import {ProfileTypeList} from 'src/types/interfaces/IProfile'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import {HirerRoles} from 'src/types/enum/HirerRole'
import ProfileField from 'src/components/fields/ProfileField'
import {useRecordContext} from 'ra-core'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import ImpersonateButton from 'src/resources/profile/ImpersonateButton'

const ActionFields = () => {
    const record = useRecordContext()
    return <Box>
        <ButtonGroup size="small" aria-label="small button group">
            <ImpersonateButton profileId={record.id} buttonView={'menuItem'}/>
        </ButtonGroup>
    </Box>
}
const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Profile'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="email:$contL&firstName:$contL&lastName:$contL&referralCode:contL"  variant={'outlined'} alwaysOn={true}  />

            <DateRangeFilterInput source={'createdAt:$between'} label="Регистрация"
                                  alwaysOn/>
            <SelectInput source={'hirerRole'} label={'Роль'} choices={HirerRoles} variant={'outlined'}/>
        </Filter>
    )
}

const ProfileList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Профили"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет профилей'} description={'You can create профиль'}
                              buttonText={'Добавить профиль'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <TextField source={'email'} label={'Email'}/>
                <SelectField source={'profileType'} label={'Тип'} choices={ProfileTypeList}/>
                <ProfileField source={'lastName'} format={'userName'} label={'ФИО'}/>
                <SelectField source={'hirerRole'} label={'Роль'} choices={HirerRoles}/>
                <TextField source={'company.name'} label={'Компания'}/>
                <TextField source={'country.name'} label={'Страна'}/>
                <TextField source={'referralCode'} label={'Referral Code'}/>
                <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
                <ActionFields/>
            </Datagrid>
        </List>
    )
}

export default ProfileList
